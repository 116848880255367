import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setIsVisible(window.scrollY > 0);
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="w-full px-[5vw] xsm:py-[2vh] sm:py-[5vh] text-[#e6e6e6] bg-secondary relative z-40">
      <h3 className="text-[1.2rem] sm:text-[1.3rem] font-bold text-center sm:text-left">
        CodMiners Infotech Pvt. Ltd.
      </h3>

      <div className="pt-[3vh] md:px-[5vw] flex justify-evenly sm:justify-between gap-4 flex-wrap">
        <div className="">
          <h3 className="pb-[5px] text-[1.2rem] font-bold text-center">
            Quick Links
          </h3>
          <ul className="font-semibold text-center">
            <li>
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={scrollToTop}>
                About us
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={scrollToTop}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={scrollToTop}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={scrollToTop}>
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className="">
          <h3 className="pb-[5px] text-[1.2rem] font-bold text-center">
            Contact Us
          </h3>
          <ul className="font-semibold text-center">
            <li>C-117, Exceluss </li>
            <li>Business Space</li>
            <li>Althan, Surat 395017</li>
            <li>+91 9376979291</li>
            <li>hr@codminers.com</li>
          </ul>
        </div>
        <div className="">
          <h3 className="pb-[10px] text-[1.2rem] font-bold text-center">
            Follow Us
          </h3>
          <ul className="font-semibold text-[1.2rem] flex max-[490px]:flex-row flex-col gap-2 items-center">
            <li>
              <Link
                to="https://www.linkedin.com/company/codminers/"
                target="_blank"
                title="LinkedIn"
                className="px-[8px] py-[3px] border border-white hover:text-secondary hover:bg-white transition-all duration-300"
              >
                <i className="fa-brands fa-linkedin-in fa-spin"></i>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.facebook.com/people/CodMiners/61572264927860/"
                target="_blank"
                title="Facebook"
                className="px-[7.5px] py-[3px] border border-white hover:text-secondary hover:bg-white transition-all duration-300"
              >
                <i className="fa-brands fa-facebook-f fa-spin w-[18.2px]"></i>
              </Link>
            </li>
            <li>
              <Link
                to="https://instagram.com/codminersinfotech?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                title="Instagram"
                className="px-[8px] py-[3px] border border-white hover:text-secondary hover:bg-white transition-all duration-300"
              >
                <i className="fa-brands fa-instagram fa-spin"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-[5vh] text-center font-bold">
        <span className="text-[1.2rem]">
          All rights are reserved ©Codminers Infotech Pvt. Ltd.
        </span>

        <div onClick={scrollTop} className="z-50">
          <span>
            <Link
              title="Back to top"
              className={`px-[10px] md:px-[12.5px] text-[#000080] bg-bgcolor text-[2rem] md:text-[2.5rem] border-2 border-[#000080] rounded-full bottom-20 right-8 fixed md:bottom-10 md:right-12 ${
                isVisible ? "visible" : "invisible"
              }`}
            >
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
