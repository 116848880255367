import ranktivity from "./../img/ranktivity.png";
import navigator from "./../img/navigator.png";
import codminers from "./../img/codminers.png";
import codminers2 from "./../img/codminers2.png";
import nilgai1 from "./../img/nilgai1.png";
import nilgai2 from "./../img/nilgai2.png";
import nilgai4 from "./../img/nilgai4.png";
import nilgai5 from "./../img/nilgai5.png";
import nilgai6 from "./../img/nilgai6.png";
import nilgai7 from "./../img/nilgai7.png";
import nilgai8 from "./../img/nilgai8.png";

const projects = [
  {
    id: 1,
    title: "Nilgai Travels",
    description:
      "A comprehensive travel planning platform that empowers users to effortlessly create personalized itineraries or explore a curated collection of pre-designed travel plans.",
    images: [nilgai1, nilgai2, nilgai4, nilgai5, nilgai6, nilgai7, nilgai8],
    technologies: ["Next.js", "tailwindcss"],
  },
  {
    id: 2,
    title: "Ranktivity",
    description:
      "Ranktivity offers comprehensive SEO tools for optimizing websites and improving online visibility.",
    images: [ranktivity, codminers2],
    technologies: [
      "Next.js",
      "tailwindcss",
      "Node.js",
      "firebase",
      "Google Cloud",
      "Puppeteer",
    ],
  },
  {
    id: 3,
    title: "Digital Navigator",
    description:
      "It optimizes web and social media for better visitor engagement and increased sales potential.",
    images: [navigator, codminers2],
    technologies: [
      "Next.js",
      "tailwindcss",
      "Python",
      "Flask",
      "Docker",
      "Google Cloud",
      "firestore",
    ],
  },
  {
    id: 4,
    title: "CodMiners Infotech Pvt. Ltd.",
    description:
      "This the company website to showcase its work to the visiters and give information about our company.",
    images: [codminers, codminers2],
    technologies: ["React", "tailwindcss", "Node.js", "Express.js", "MongoDB"],
  },
];

export default projects;
